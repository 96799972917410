<template>
  <div class="home">
    <Navigation @laodIndex= 'aaaaa'/>
    <div v-if="fff==0">
       <Home />
    </div>
    <div v-if="fff==1">
        <About/>
    </div>
    <div v-if="fff==2">
      <News  />
    </div>
   
  
   
    <BottomNav />
  </div>
</template>

<script setup>
import { ref } from 'vue'
  import Navigation from '../components/navigation/navigation.vue'
  import Home from "../views/home/home.vue"
  import BottomNav from "../components/bottomNav/bottomNav.vue"
  import About from "../views/about/about.vue"
  import News from "../views/news/news.vue"

  const fff=ref(0)

      const aaaaa=(val)=>{
        fff.value=val
        console.log(val)
      }
  // import { defineComponent } from 'vue';

  // defineComponent({
  //   components:{
  //     navigation,
  //   }
  // })
  // let _value = ref('')

  // const clickindex = (val)=>{
  //   console.log(val);
  //   _value.value = val._value
  // }

</script>


<style lang="less" scoped>
.home {
  width: 100%;
  margin: auto;
 
}
</style>
