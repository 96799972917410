<template>
  <div class="navigation">
    <div @click="laodIndex('首页', 0)">
      <img
        class="logo"
        src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/logo.png"
      />
    </div>
    <div class="nav-content">
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="fontIndex == index ? 'navfont1' : 'nav-font'"
        @click="laodIndex(item, index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="nav-right">
      <!-- <div class="font1">学员登录</div> -->
      <div class="font2">
        <a href="https://teacher.badouknow.com/">讲师登录</a>
      </div>
    </div>
    <img
      @click="upMenu(1)"
      src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/menu.svg"
      class="menu"
    />

    <div v-if="menu" class="mask" @click="upMenu(0)"></div>
    <div v-if="menu" class="nav-menu">
      <img
        class="logo"
        src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/logo.png"
      />
      <img
        @click="upMenu(0)"
        class="x-right"
        src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/x.svg"
      />
      <div class="ul">
        <div
          v-for="(item, index) in listData"
          :key="index"
          :class="fontIndex == index ? 'li ok' : 'li'"
          @click="
            laodIndex(item, index);
            upMenu(0);
          "
        >
          {{ item }}
          <img
            class="nav-right"
            src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/nav-right.svg"
          />
        </div>
      </div>
      <!-- <div class="font2">
        <a href="https://teacher.badouknow.com/">讲师登录</a>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
// import {onMounted} from 'vue'
const list = ["首页", "关于我们", "新闻动态"];
const listData = ["首页", "关于我们", "新闻动态", "讲师登录"];
// onMounted(() => {
//       document.title = '新的页面标题'
//     })

let menu = ref(0);
let fontIndex = ref(0);
const emit = defineEmits(["laodIndex"]);
const laodIndex = (item, index) => {
  fontIndex.value = index;
  emit("laodIndex", fontIndex.value);
  console.log(fontIndex.value, item);
  if (item == "讲师登录") {
    window.location.href = "https://teacher.badouknow.com/";
    return false;
  }
  if ((index = 0)) {
    console.log(item);
    // if (item == 3) {
    //   window.location.href = "https://teacher.badouknow.com/";
    // }
    this.$nextTick(() => {
      document.title = "新的页面标题";
    });
  }
};

const upMenu = (e) => {
  menu.value = e;
  // console.log(e)
};
</script>

<style lang="less" scoped>
a {
  color: #ffffff;
  text-decoration: none;
}
.nav-menu {
  display: none;
}
.navigation {
  width: 100%;
  height: 68px;
  background: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;

  .logo {
    width: 103px;
    height: 32px;
  }
  .menu {
    display: none;
  }

  .nav-content {
    width: 220px;
    display: flex;
    justify-content: space-between;

    .nav-font {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }

    .navfont1 {
      color: #333333;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }

  .nav-right {
    display: flex;

    .font1 {
      width: 114px;
      height: 38px;
      border: 1px solid #4395f8;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #4395f8;
      line-height: 38px;
    }

    .font2 {
      width: 114px;
      height: 38px;
      background: #4395f8;
      border-radius: 19px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 38px;
      margin-left: 20px;
    }
  }
}

@media (min-width: 10px) and (max-width: 599px) {
  .navigation {
    justify-content: space-between;
    .nav-content {
      display: none;
    }
    .nav-right {
      display: none;
    }
    .menu {
      display: inline-block;
      margin-right: 15px;
    }
    .logo {
      margin-left: 15px;
    }
  }

  .mask {
    position: fixed;
    top: 0;
    width: 100%;
    height: 1500px;
    background-color: #333333;
    opacity: 0.6;
  }
  .nav-menu {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 75%;
    height: 1500px;
    background-color: white;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 15px;
    padding: 15px 0;
    .logo {
      width: 30%;
      height: auto;
      margin-bottom: 15px;
      margin: 0 15px;
    }
    .x-right {
      float: right;
      margin-right: 6%;
      margin-top: 10px;
    }
    .ul {
      margin-top: 30px;
      .li {
        padding: 15px;
        .nav-right {
          float: right;
          margin-top: 3px;
        }
      }
      .ok {
        background-color: #f5f6f7;
      }
    }
  }
}
</style>
