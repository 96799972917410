<template>
  <div class="box">
    <div class="box1">
      <div class="box2">
        <img class="img1" src="https://cdn.badouknow.com/badou_website/logo1.png" />
        <div class="font3">深圳市八斗传媒有限公司 保留所有权利</div>
      </div>
      <div class="box3">
        <div class="font4">联系我们</div>
        <!-- <div class="font5">官方客服：0755-88888888</div> -->
        <div class="font5">工作时间：9:30-18:30</div>
        <!-- <div class="font5">商务对接：13433851386</div> -->
      </div>
      <div class="box4">
        <div class="box5">
          <img class="img2" src="https://cdn.badouknow.com/badou_website/gzh.jpg" />
          <div class="font6">八斗课堂公众号</div>
        </div>
        <div class="box5">
          <img class="img2" src="https://cdn.badouknow.com/badou_website/erweima1.jpg" />
          <div class="font6">八斗课堂商务合作</div>
        </div>
      </div>
    </div>
    <div class="font">
      Copyright 2022-2023 www.badouknow.com. All Right Reserved
      <div>
        <span class="font1">深圳市八斗传媒有限公司 版权所有</span>
        <div><a href="http://beian.miit.gov.cn/">粤ICP备2023018321号</a></div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: underline;
}

.box {
  width: 100%;
  // height: 468px;
  background: #000000;

  .box1 {
    width: 100%;
    height: 295px;
    display: flex;
    justify-content: space-around;

    .box2 {
      margin-top: 122px;

      .img1 {
        width: 182px;
        height: 57px;
      }

      .font3 {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        margin-top: 39px;
      }
    }
  }

  .box3 {
    margin-top: 122px;

    .font4 {
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .font5 {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 45px;
    }
  }

  .box4 {
    width: 284px;
    margin-top: 122px;
    display: flex;
    justify-content: space-between;

    .img2 {
      width: 122px;
      height: 122px;
      background: #ffffff;
    }

    .font6 {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }

    .box5 {
      margin-right: 40px;
    }
  }

  .font {
    margin-top: 125px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 25px;

    .font1 {
      margin: 0 8px;
    }

    .font2 {
      color: #4395f8;
    }
  }
}

@media (min-width: 10px) and (max-width: 599px) {
  .box {
    height: auto;
    padding: 0 3%;

    .box1 {
      padding-top: 15px;
      display: block;
      height: auto;

      .box2 {
        margin-top: 30px;

        .font3 {
          text-align: left;
          margin-top: 15px;
        }
      }
    }

    .box3 {
      margin-top: 60px;
    }

    .box4 {
      margin-top: 60px;
      margin: auto;
    }

    .font {
      margin-top: 60px;
      padding: 0 6%;
      padding-bottom: 30px;
      line-height: 25px;
    }
  }
}
</style>
