<template>
    <div>
        <div class="top">

            <img class="top" src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/news/image1.png" alt="">
        </div>
        <div v-if="state">
            <div class="title">公司新闻</div>
            <!-- <div>
                <div class="box" v-for="(iten, index) in 5" :key="index" @click="laodState">
                    <div class="img"></div>
                    <div class="font">
                        <div class="font1">直播规则<span class="font2">2022-12-14</span></div>
                        <div class="font3">临近315，应该有很多老师已经发现，抖音等平台对于直播间的违规把控更加严格。那么要如何才能够在直播过程中不“踩雷”呢？本期八斗课堂，和我们一起看看吧~</div>
                    </div>
                </div>
            </div> -->



            <!-- <div class="paging">
                <el-pagination class="paging1" v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                    :page-sizes="[100, 200, 300, 400]" :small="small" :disabled="disabled" :background="background"
                    layout="total, sizes, prev, pager, next, jumper" :total="400" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" />

            </div> -->
            <div class="box4">
            <img class="img3" src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/news/wu.png" />
                <div class="font6">暂无新闻内容</div>
            </div>
        </div>


        <!-- <div class="box2" v-else>
            <div class="title1">宝安区委书记莅临深圳市千岸科技有限公司参观指导</div>
            <div class="font4">2020-12-18</div>
            <div class="box3" v-for="(item,index) in 2" :key="index">
                <div class="font5">宝安区委书记莅临深圳市千岸科技有限公司参观指导</div>
                <div>2017年1月10日上午，深圳市宝安区区委书记黄敏率队莅临深圳市千岸科技有限公司，对深圳高新技术企业代表进行了各部门多方位的工作考察和参观调研。
                    区委书记一行人听取了何定董事长关于千岸发展战略的介绍，期间各位领导对千岸的海外销售情况、线上平台研发等方面一一作了详细的了解，就千岸的发展壮大，提出了宝贵的意见并作出重要指示。宝安区政府领导的热心关怀与鼓励，更加激发了千岸人不断拓展革新的工作热情，作为深圳市跨境电商的龙头企业，立足宝安，谱写新的乐章。
                </div>
                <div  class="font5">
                    区委书记一行人听取了何定董事长关于千岸发展战略的介绍，期间各位领导对千岸的海外销售情况、线上平台研发等方面一一作了详细的了解，就千岸的发展壮大，提出了宝贵的意见并作出重要指示。宝安区政府领导的热心关怀与鼓励，更加激发了千岸人不断拓展革新的工作热情，作为深圳市跨境电商的龙头企业，立足宝安，谱写新的乐章。
                </div>
            </div>
        </div> -->


    </div>
</template>

<script  setup>
import { ref } from 'vue'
import {onMounted} from 'vue'

const currentPage4 = ref(1)
const pageSize4 = ref(100)
const small = ref(false)
const background = ref(false)
const disabled = ref(false)
const state = ref(true)


const handleSizeChange = (val) => {
}

const handleCurrentChange = (val) => {
} 

const laodState = ()=>{
    state.value = !state.value
}


onMounted(() => {
      document.title = '新闻动态 - 八斗课堂'
    })
</script >

<style lang="less" scoped>

.box4{
    width: 100%;
    text-align: center;
    margin-top: 30px;
    padding-bottom: 120px;
    
    .img3{
    width:350px;
    height:300px;
}
}
.font6{
        font-size: 20px;
        color: #999999;
        margin: auto;
    }

.top {
    width: 100%;
    height: auto;

}

.title {
    height: 187px;
    line-height: 187px;
    width: 100%;
    text-align: center;
    font-size: 48px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #333333;
}

.box:hover {
    box-shadow: 0px 0px 24px 0px rgba(0, 63, 154, 0.12);
}

.box2 {
    width: 1280px;
    padding-top: 111px;
    padding-bottom: 160px;
    margin: auto;

    .title1 {
        font-size: 30px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
    }

    .font4 {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #999999;
        margin-top: 6px;
        margin-bottom: 20px;
    }

    .box3 + .box3{
        margin-top: 80px;
    }
    .box3 {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
    }
    .font5{
        text-indent: 32px;
        margin-top: 30px;
    }
}

.box {
    width: 1280px;
    height: 270px;
    background: #ffffff;
    border-radius: 18px;
    margin: auto;
    display: flex;
    align-items: center;

    .img {
        height: 230px;
        width: 363px;
        background-color: aqua;
        border-radius: 18px;
        margin-right: 30px;
    }

    .font1 {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #333333;

    }

    .font2 {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
        margin-left: 8px;
    }

    .font3 {
        margin-top: 20px;
        width: 806px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
    }
}

.paging {
    width: 1920px;

}

@media (min-width: 10px) and (max-width: 599px) {
	
	.title{
		font-size: 24px;
		line-height: 60px;
		height: auto;
	}
	.box4{
		.img3{
			width: 50%;
			height: auto;
		}
		.font6{
			font-size: 14px;
			margin-top: 15px;
		}
	}
}
</style>