<template>
  <div class="container">
    <!-- <Swiper></Swiper> -->
    <!-- <div class="banner">
            <img class="img" src="https://cdn.badouknow.com/badou_website/image1.png" />
        </div> -->
    <!-- <a href="https://teacher.badouknow.com/#/applycheckina">
      <img
        style="width: 100%; height: auto; cursor: pointer"
        src="https://cdn.badouknow.com/badou_website/index.png"
    /></a> -->
    <el-carousel autoplay :show-arrow="false">
      <el-carousel-item>
        <a href="https://teacher.badouknow.com/#/applycheckina">
          <img
            class="banner-c"
            src="https://cdn.badouknow.com/badou_website/home/banner1.png"
          />
        </a>
      </el-carousel-item>
      <el-carousel-item>
        <img
          class="banner-c"
          src="https://cdn.badouknow.com/badou_website/home/banner2.png"
        />
      </el-carousel-item>
    </el-carousel>

    <div class="content1">
      <div class="title">
        <div class="title1">
          <div class="font1">多端知识付费解决方案</div>
          <div class="font2">
            覆盖知识数智化全渠道经营场景，占据短视频公域流量入口，知识变现快速高效
          </div>
        </div>
      </div>

      <div class="box">
        <div class="box1" v-for="(item, index) in list" :key="index">
          <div class="box2">
            <img class="img1" :src="item.img" />
            <div class="font3">{{ item.title }}</div>
          </div>
          <div class="box3">{{ item.introduction }}</div>
        </div>
      </div>

      <div class="box4">
        <div></div>
      </div>
    </div>

    <div class="content2">
      <div class="title2">
        <div class="font4">保姆级运营服务</div>
        <div class="font5">提供专业的运营团队及内容团队陪跑达人</div>
      </div>
      <div class="box4" v-for="(item, index) in employ" :key="index">
        <div><img class="img2" :src="item.img" /></div>
        <div>
          <div class="font6">{{ item.num }}</div>
          <div class="font7">{{ item.title }}</div>
          <div class="font8">{{ item.content }}</div>
        </div>
      </div>
      <div class="box4">
        <div class="mobile">
          <img
            class="img2"
            src="https://cdn.badouknow.com/badou_website/home/img2.png"
          />
        </div>
        <div>
          <div class="font6">No.2</div>
          <div class="font7">售前/售后客服</div>
          <div class="font8">
            达人无需售后，八斗课堂客服团队365天*18小时在线服务用户
          </div>
        </div>
        <div class="web">
          <img
            class="img3"
            src="https://cdn.badouknow.com/badou_website/home/img2.png"
          />
        </div>
      </div>
      <div class="box4">
        <div>
          <img
            class="img2"
            src="https://cdn.badouknow.com/badou_website/home/img3.png"
          />
        </div>
        <div>
          <div class="font6">No.3</div>
          <div class="font7">运营指导</div>
          <div class="font8">
            根据达人的要求，八斗课堂提供从课程定位，视频内容，直播脚本设计跟播复盘，数据优化等全方位运营服务，助力达人提升收益
          </div>
        </div>
      </div>
    </div>

    <div class="content3">
      <!-- <div class="title3">
        <div class="font9">丰富营销组件助力销售转化</div>
        <div class="font10">
          覆盖知识数智化全渠道场景，占据短视频公域流量入口，知识变现快速高效
        </div>
      </div> -->
      <img
        class="content3-image"
        style="width: 100%; height: 50vh"
        src="https://cdn.badouknow.com/badou_website/home/image.png"
      />
      <div class="content3-box">
        <div class="content3-font1">最全面的知识付费解决方案服务商</div>
        <div class="content3-font2">
          覆盖知识数智化全渠道场景，占据短视频公域流量入口，知识变现快速高效
        </div>
        <div class="content3-introduce">
          <div class="introduce introduce-s">
            <div class="introduce-num">
              1<span class="introduce-font">亿+</span>
            </div>
            <div class="introduce-font2">终端用户数</div>
          </div>
          <div class="introduce introduce-s">
            <div class="introduce-num">
              30000<span class="introduce-font">+</span>
            </div>
            <div class="introduce-font2">讲师入驻</div>
          </div>
          <div class="introduce introduce-s">
            <div class="introduce-num">
              50000<span class="introduce-font">+</span>
            </div>
            <div class="introduce-font2">达人入驻</div>
          </div>
          <div class="introduce-s">
            <div class="introduce-num">
              20000<span class="introduce-font">+</span>
            </div>
            <div class="introduce-font2">在线课程</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content4">
      <div class="title4">
        <div class="font12">头部达人都在选择我们</div>
        <div class="font13">
          根据客户入驻八斗课堂后的全生命周期进行匹配分析，提供环绕式的运营陪跑服务
        </div>
      </div>
      <div class="box6">
        <img
          class="img6"
          src="https://cdn.badouknow.com/badou_website/home/zuo.png"
          :class="{
            disabled: currentPage > 1,
          }"
          @click="preSwiper"
        />
        <div class="box7" v-for="item in showList" :key="item">
          <div>
            <img class="img5" :src="item.head" alt="" />
          </div>
          <div class="box8">
            <div class="font14">{{ item.name }}</div>
            <div class="font15">
              {{ item.introduce }}
            </div>
          </div>
        </div>
        <img
          class="img6"
          src="https://cdn.badouknow.com/badou_website/home/you.png"
          :class="{
            disabled: currentPage < maxPage,
          }"
          @click="nextSwiper"
        />
      </div>

      <div class="box6-ac">
        <img
          class="img6-l"
          src="https://cdn.badouknow.com/badou_website/home/left-tab.png"
          :class="{
            disabled: currentPage > 1,
          }"
          @click="preSwiper"
        />
        <div class="box7" v-for="item in showListB" :key="item">
          <div>
            <img class="img5" :src="item.head" alt="" />
          </div>
          <div class="box8">
            <div class="font14">{{ item.name }}</div>
            <div class="font15">
              {{ item.introduce }}
            </div>
          </div>
        </div>
        <img
          class="img6-r"
          src="https://cdn.badouknow.com/badou_website/home/right-tab.png"
          :class="{
            disabled: currentPage < maxPage,
          }"
          @click="nextSwiper"
        />
      </div>
      <div class="indicate">
        <div class="font16" :class="{ font17: currentPage == 2 }"></div>
        <div class="font16" :class="{ font17: currentPage == 1 }"></div>
      </div>
      <div class="indicateA">
        <div class="font16-a" :class="{ font17B: currentPage == 1 }"></div>
        <div class="font16-a" :class="{ font17B: currentPage == 2 }"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";

// const content = [
//   {
//     img: "",
//     name: "",
//     content: "",
//   },
// ];

const list = [
  {
    img: "https://cdn.badouknow.com/badou_website/home/douying.png",
    title: "抖音",
    introduction:
      "(视频挂载，直播挂载)专属定制属于自己的抖音独立小程序，链接公域流量，打造品牌影响力",
  },
  {
    img: "https://cdn.badouknow.com/badou_website/home/kuaishou.png",
    title: "快手",
    introduction:
      "(视频挂载，直播挂载)专属定制属于自己的抖音独立小程序，链接公域流量，打造品牌影响力",
  },
  {
    img: "https://cdn.badouknow.com/badou_website/home/weixing.png",
    title: "微信",
    introduction:
      "(视频挂载，直播挂载)专属定制属于自己的抖音独立小程序，链接公域流量，打造品牌影响力",
  },
];

const employ = [
  {
    img: require("@/assets/home/img1.png"),
    num: "No.1",
    title: "保姆级运营服务",
    content: "提供专业的运营团队及内容团队陪跑达人",
  },
];

const allList = ref([
  {
    head: "https://cdn.badouknow.com/badou_website/home/head1.jpg",
    name: "电影十级学者",
    introduce: "深耕电影行业，对电影有比较深刻的了解",
  },
  {
    head: "https://cdn.badouknow.com/badou_website/home/head2.jpg",
    name: "萌鬼到漫画",
    introduce: "有趣的漫画小剧场，萌萌哒的地府逗趣日常",
  },
  {
    head: "https://cdn.badouknow.com/badou_website/home/head3.jpg",
    name: "老宋的微醺23点",
    introduce: "带您品味酒的滋味，如何正确调酒，才能好喝",
  },
  {
    head: "https://cdn.badouknow.com/badou_website/home/head4.jpg",
    name: "徐博士",
    introduce: "徐博士带您了解酒的品质，什么样的酒彩色好酒",
  },
]);
const MAX_NUM = 3;

const currentPage = ref(1);

const maxPage = computed(() => Math.ceil(allList.value.length / MAX_NUM));
const showList = computed(() =>
  allList.value.slice(
    (currentPage.value - 1) * MAX_NUM,
    currentPage.value * MAX_NUM
  )
);

const MAX_NUMs = 2;
const showListB = computed(() =>
  allList.value.slice(
    (currentPage.value - 1) * MAX_NUMs,
    currentPage.value * MAX_NUMs
  )
);
const preSwiper = () => {
  if (currentPage.value > 1) {
    currentPage.value -= 1;
  }
};
const nextSwiper = () => {
  if (currentPage.value < maxPage.value) {
    currentPage.value += 1;
  }
};

onMounted(() => {
  document.title = "八斗课堂 - 可靠的抖音/快手至少付费变现平台";
});
</script>

<style lang="less" scoped>
/deep/.el-carousel__container {
  height: 60vh;
}
.mobile {
  display: none;
}
.banner-c {
  width: 100%;
  height: 63vh;
  cursor: pointer;
}
.imgaa {
  width: 641px;
  height: auto;
  margin-top: 145px;
  position: absolute;
  top: 0;
  left: 30%;
  z-index: 2;
}

.content1 {
  height: 743px;

  .title1 {
    // height: 113px;
    padding-top: 140px;
    text-align: center;

    .font1 {
      font-size: 48px;
      font-weight: 600;
      color: #333333;
    }

    .font2 {
      font-size: 22px;
      font-weight: 400;
      color: #333333;
      margin-top: 16px;
    }
  }

  .box1:nth-child(1) {
    margin-right: 57px;
  }

  .box1:nth-child(2) {
    margin-right: 57px;
  }

  .box {
    display: flex;
    padding: 0 320px;
    justify-content: center;
    margin: auto;
    margin-top: 64px;

    .box1 {
      width: 389px;
      height: 266px;

      .box2 {
        display: flex;
        align-items: center;

        .img1 {
          width: 150px;
          height: 150px;
          margin-right: 24px;
        }

        .font3 {
          font-size: 40px;
          font-weight: 600;
          color: #333333;
        }
      }

      .box3 {
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        margin-top: 32px;
      }
    }
  }
}

.content2 {
  padding-bottom: 99px;

  .title2 {
    height: 113px;
    text-align: center;

    .font4 {
      font-size: 48px;
      font-weight: 600;
      color: #333333;
    }

    .font5 {
      font-size: 22px;
      font-weight: 400;
      text-align: center;
      color: #333333;
      margin-top: 16px;
    }
  }

  .box4 {
    width: 1286px;
    height: 382px;
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 48px;

    .img2 {
      width: 511px;
      height: 382px;
      margin-right: 49px;
    }

    .img3 {
      width: 511px;
      height: 382px;
      margin-left: 49px;
    }

    .font6 {
      font-size: 49px;
      font-weight: 900;
      color: #4395f8;
    }

    .font7 {
      font-size: 28px;
      font-weight: 600;
      color: #333333;
    }

    .font8 {
      font-size: 20px;
      color: #333333;
      width: 720px;
    }
  }
}

.content3 {
  position: relative;
  height: 50vh;
  .content3-box {
    width: 100%;
    position: absolute;
    top: 0;
    text-align: center;
    .content3-introduce {
      display: flex;
      justify-content: center;
      margin-top: 2%;
      .introduce {
        margin-right: 112px;
      }
      .introduce-font {
        font-size: 36px;
        font-weight: 500;
        color: #ffffff;
      }
      .introduce-num {
        font-size: 80px;
        font-weight: 600;
        color: #ffffff;
      }
      .introduce-font2 {
        font-size: 26px;
        text-align: center;
        color: #ffffff;
      }
    }
    .content3-image {
    }

    .content3-font1 {
      font-size: 48px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      margin-top: 80px;
    }
    .content3-font2 {
      font-size: 22px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      margin-top: 16px;
    }
  }

  .title3 {
    .font9 {
      font-size: 48px;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }

    .font10 {
      font-size: 22px;
      font-weight: 400;
      text-align: center;
      color: #333333;
      margin-top: 16px;
    }
  }

  .font11 {
    width: 475px;
    height: 178px;
    font-size: 30px;
    font-weight: 600;
    text-align: left;
    color: #333333;
    position: absolute;
    right: 320px;
    top: 435px;
    position: absolute;
    top: 40%;
    right: 15%;
  }

  // .box5 {
  //     width: 100%;
  //     height: 1049px;
  //     // background-image: url('https://cdn.badouknow.com/badou_website/home/image2.png');
  //     // background-size: 100% 100%;
  //     margin-top: 48px;
  //     position: relative;

  //     .img4 {
  //         width: 321px;
  //         height: 320px;
  //         position: absolute;
  //         right: 722.8px;
  //         top: 73.6px;
  //         opacity: 0.1;
  //     }
  // }
}
.indicateA {
  display: none;
}

.content4 {
  margin-top: 160px;

  .font12 {
    font-size: 48px;
    font-weight: 600;
    text-align: center;
    color: #333333;
  }

  .font13 {
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    color: #666666;
    margin-top: 16px;
  }

  .box7 {
    width: 394px;
    height: 488px;
    position: relative;
  }

  .font14 {
    font-size: 33px;
    font-weight: 600;
    text-align: center;
    color: #333333;
    margin-top: 114px;
  }

  .font15 {
    width: 305px;
    font-size: 14px;
    font-weight: 400;
    margin: auto;
    color: #333333;
    margin-top: 27px;
    text-align: center;
  }

  .box8 {
    width: 394px;
    height: 412px;
    background: #f4f8fe;
    border-radius: 49px;
    position: absolute;
    top: 76px;
  }

  .box6 {
    width: 1408px;
    height: 488px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-top: 48px;
  }
  .box6-ac {
    display: none;
  }

  .img5 {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    left: 122px;
    z-index: 2;
  }

  .img6 {
    width: 24px;
    height: 48px;
    cursor: pointer;
  }

  .indicate {
    width: 100%;
    display: flex;
    margin-top: 72px;
    // background-color: aqua;
    padding-top: 72px;
    padding-bottom: 196px;
    justify-content: center;
    .font16 {
      width: 42px;
      height: 10px;
      background-color: #4395f8;
      border-radius: 5px;
      margin-right: 8px;
    }

    .font17 {
      width: 21px;
      height: 10px;
      background: #f0f5ff;
      border-radius: 5px;
      margin-right: 8px;
    }
  }
}

@media (min-width: 10px) and (max-width: 599px) {
  .indicate {
    display: none !important;
  }
  .indicateA {
    display: flex;
    margin-top: 40px !important;
    padding-bottom: 64px !important;
    justify-content: center;
    .font17B {
      width: 12px !important;
      height: 4px !important;
      background: #4395f8 !important;
      border-radius: 2px !important;
    }
    .font16-a {
      width: 4px;
      height: 4px;
      background: #c3c3c3;
      border-radius: 2px;
      margin-right: 3px;
    }
  }
  .content4 {
    margin-top: 40px !important;
    position: relative;
  }
  .box6 {
    display: none !important;
  }
  .font12 {
    font-size: 16px !important;
  }
  .font13 {
    font-size: 12px !important;
  }
  .box6-ac {
    display: flex !important;
    justify-content: space-around;
    .img6-l {
      width: 26px;
      height: 26px;
      position: absolute;
      left: 6px;
      top: 246px;
      z-index: 2;
    }
    .img6-r {
      width: 26px;
      height: 26px;
      position: absolute;
      right: 6px;
      top: 246px;
      z-index: 2;
    }
    .img5 {
      width: 70px !important;
      height: 70px !important;
      left: 30% !important;
      top: 40px;
    }
    .box7 {
      width: 40% !important;
      height: 300px !important;
    }
    .box8 {
      width: 100% !important;
      height: 239px !important;
      border-radius: 18px !important;
      box-shadow: 0px 3px 12px 0px #f4f8fe;
      .font14 {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #333333;
        margin-top: 60px !important;
      }
      .font15 {
        width: 90% !important;
        font-size: 12px;
        text-align: center;
        color: #333333;
      }
    }
  }
  /deep/.el-carousel__container {
    height: 160px !important;
  }
  .mobile {
    display: block;
  }
  .web {
    display: none;
  }

  .banner-c {
    height: 160px;
  }
  .content1 {
    height: auto;
    .title1 {
      padding-top: 30px;
      .font1 {
        font-size: 24px;
      }
      .font2 {
        font-size: 14px;
        margin: 15px 30px;
      }
    }

    .box {
      display: block;
      padding: 0 3%;
      margin-top: 30px;
      .box1 {
        width: auto;
        height: auto;
        margin-bottom: 30px;
        margin-right: 0;
        .box3 {
          font-size: 14px;
          margin-top: 15px;
        }
        .box2 {
          .img1 {
            width: 64px;
            height: 64px;
          }
          .font3 {
            font-size: 24px;
          }
        }
      }
    }
  }
  .content2 {
    width: 100%;
    margin-top: 60px;
    padding-bottom: 60px;
    .box4 {
      width: 94%;
      height: auto;
      margin: 0 3%;
      margin-top: 30px;
      display: block;

      .img2 {
        width: 100%;
        height: auto;
        margin-right: 0;
      }
      .font6 {
        font-size: 40px;
        margin-top: 10px;
      }
      .font7 {
        font-size: 24px;
        margin: 10px 0;
      }
      .font8 {
        width: 100%;
        line-height: 20px;
        font-size: 14px;
      }
    }
    .title2 {
      height: auto;
      .font4 {
        font-size: 24px;
      }
      .font5 {
        font-size: 14px;
      }
    }
  }
  .content3 {
    // padding-bottom: 60px;
    height: 349px !important;
    .content3-font1 {
      margin-top: 34px !important;
      font-size: 20px !important;
    }
    .content3-font2 {
      font-size: 12px !important;
      padding: 0 28px;
    }
    .content3-image {
      width: 100% !important;
      object-fit: cover;
      height: 355px !important;
    }
    .content3-introduce {
      justify-content: space-between !important;
      flex-wrap: wrap;
      margin-top: 20px !important;
    }
    .introduce {
      margin-right: 0 !important;
    }
    .introduce-s {
      width: 50% !important;
      text-align: center !important;
      margin-bottom: 24px;
    }

    .introduce-num {
      font-size: 40px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .introduce-font {
      font-size: 20px !important;
    }
    .img12 {
      width: 120%;
    }
    .title3 {
      .font9 {
        font-size: 24px;
      }
      .font10 {
        font-size: 14px;
        margin: 15px 35px;
      }
    }

    .img13 {
      width: 100%;
    }
  }
}
</style>
