<template>
    <div>
        <div class="top">
            <img style="width: 100%;height: auto;" src='https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/about/44.png' alt="">
            <!-- <div class="font">
                <div class="font1">关于我们</div>
                <div class="font2">About Us</div>
            </div> -->
        </div>
        <div class="content">
            <div class="title1">
                <div class="font3">我们努力践行的文化</div>
                <div class="font4">推动中国知识数字经济高质量发展和打造一体化知识数智化智能服务生态体系</div>
            </div>
            <div class="box1">
                <div class="m_xing">
                    <div class="img1">
                        <img class="img11" src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/about/img1.png" alt="">
                    </div>
                    <div class="box2">
                        <div class="box5">
                            <div class="font5">我们的愿景</div>
                            <div class="font7">our vision</div>
                            <div class="line"></div>
                            <div class="font9">成为国内领先的流量内容变现服务平台，创造优质流量变现产品</div>
                        </div>
                    </div>
                </div>

                <div class="m_xing">
                    <div class="box2 box2_2">
                        <div class="box5">
                            <div class="font5">我们的使命</div>
                            <div class="font7">our mission</div>
                            <div class="line"></div>
                            <div class="font10">为流量主提供更高的流量价值回报</div>
                        </div>
                    </div>
                    <div class="img1">
                        <img class="img11" src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/about/img2.png" alt="">
                    </div>

                </div>
                <div class="m_xing">
                    <div class="img1">
                        <img class="img11" src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/about/img3.png" alt="">
                    </div>
                    <div class="box2 box2_2">
                        <div class="box5">
                            <div class="font5">价值观</div>
                            <div class="font7">values</div>
                            <div class="line"></div>
                            <div class="font10">效率、责任、团结、创新、真诚</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="bottom">
            <div class="title2">
                <div class="font12">我们始终秉承的理念</div>
                <div class="font13">以客户为中心 以发展为根本 及时响应 立即行动</div>
            </div>
            <div class="box6">
                <div class="box7">
                    <div class="box8">
                        <img class="img2" src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/about/lan.png" />
                        <img class="img3" src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/about/lan1.png" />
                    </div>
                    <div>
                        <div class="font14">专业技术</div>
                        <div class="font15">我们拥有行业前沿开发技术实力和资深工程研发团队，为您的商业变现及产品需求保驾护航。</div>
                    </div>
                </div>
                <div class="box7">
                    <div class="box8">
                        <img class="img2" src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/about/fen.png" />
                        <img class="img3" src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/about/fen1.png" />
                    </div>
                    <div>
                        <div class="font14">贴心服务</div>
                        <div class="font15">坚持优质服务，无论任何时候，您遇到困难、疑惑，我们都会及时提供精准有效的解决方案。</div>
                    </div>
                </div>
                <div class="box7">
                    <div class="box8">
                        <img class="img2" src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/about/huang.png" />
                        <img class="img3" src="https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/about/huang1.png" />
                    </div>
                    <div>
                        <div class="font14">创业伙伴</div>
                        <div class="font15">每一位客户都当作创业伙伴，期盼在未来成长的道路上，一路相伴，携手前行。</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue'
onMounted(() => {
    document.title = '关于我们 - 八斗课堂'
})
</script>

<style lang="less" scoped>
.top {
    width: 100%;
    // height: 988px;
    // background-image: url('https://badou-image-1316940842.cos.ap-guangzhou.myqcloud.com/badou_website/about/image1.png');
    // background-size: 100% 100%;
    // position: relative;

    .font {
        position: absolute;
        top: 137px;
        left: 320px;

        .font1 {
            font-size: 40px;
            font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Regular;
            font-weight: 400;
            color: #4395f8;
        }

        .font2 {
            font-size: 80px;
            font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Regular;
            font-weight: 400;
            color: #4395f8;
        }
    }

}

.content {
    padding-top: 71px;

    .font3 {
        font-size: 48px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #333333;
    }

    .font4 {
        font-size: 22px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #666666;
        margin-top: 16px;
    }

    .box1 {
        width: 1281px;
        height: 854px;
        margin: auto;
        display: flex;

        margin-top: 49px;

        
        .m_xing {
            height: 854px;
            width: 427px;
            display: flex;
            flex-direction: column;
        }

        .img11 {
            width: 427px;
            height: 427px;
            cursor: pointer;
        }

        .img1 {
            width: 427px;
            height: 427px;
            overflow: hidden;
        }

        .box2 {
            width: 426px;
            height: 427px;
            background: #f5f6f7;
            text-align: center;

        }

        
        // .box3 {
        //     width: 426px;
        //     height: 427px;
        //     background: #4395f8;
        //     text-align: center;
        // }
    }

    .line {
        width: 31px;
        height: 1px;
        background-color: #e0e0e0;

        margin: auto;
    }

    .line1 {
        width: 31px;
        height: 1px;
        background-color: #ffffff;
        margin: auto;
    }

    .font5 {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #333333;
        letter-spacing: 0.54px;
        margin-top: 154px;
    }

    .font7 {
        font-size: 10px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #999999;
        margin-bottom: 10px;
    }

    .font6 {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0.54px;
        margin-top: 164px;
    }

    .font8 {
        opacity: 0.68;
        font-size: 10px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px;
    }

    .font9 {
        width: 154px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #666666;
        margin: auto;
        margin-top: 10px;

    }

    .font10 {
        width: 210px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #666666;
        margin: auto;
        margin-top: 10px;
    }

    .font11 {
        width: 170px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        margin: auto;
        margin-top: 10px;
    }
}

.bottom {
    padding-top: 160px;

    .title2 {
        .font12 {
            font-size: 48px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            text-align: center;
            color: #333333;
        }

        .font13 {
            font-size: 22px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #666666;
            margin-top: 16px;
        }
    }

    .box6 {
        width: 1233px;
        height: 135px;
        display: flex;
        justify-content: space-between;
        margin: auto;
        margin-top: 74px;
        margin-bottom: 195px;
        text-align: center;

        .img2 {
            width: 58px;
            height: 58px;
        }

        .img3 {
            width: 47px;
            height: 15px;
            margin: auto;
        }

        .box7 {
            width: 411px;
            height: 135px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            box-shadow: 0px 8px 30px 0px rgba(67,149,248,0.10); 
            border-radius: 20px;
            margin-right: 23px;
        }

        .box8 {
            width: 58px;
            height: 58px;
        }

        .font14 {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: left;
            color: #333333;
        }

        .font15 {
            width: 279px;
            font-size: 11px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: left;
            color: #666666;
            margin-top: 6px;
        }
    }
}

@media (min-width: 600px) {
	.m_xing:hover {
	    
	    .img11 {
	        transform: scale(1.1);
	        transition: 1s;
	    }
	
	    .box2 {
	        width: 426px;
	        height: 427px;
	        background: #4395f8;
	        text-align: center;
	        color: #ffffff !important;
	        cursor: pointer;
	
	        .font5 {
	            color: #ffffff;
	        }
	
	        .font7 {
	            color: #ffffff;
	        }
	
	        .font10 {
	            color: #ffffff;
	        }
	
	        .font9 {
	            color: #ffffff;
	        }
	    }
	
	}
	.box2:hover {
	    width: 426px;
	    height: 427px;
	    background: #4395f8;
	    text-align: center;
	    color: #ffffff !important;
	    cursor: pointer;
	
	    .font5 {
	        color: #ffffff;
	    }
	
	    .font7 {
	        color: #ffffff;
	    }
	
	    .font10 {
	        color: #ffffff;
	    }
	
	    .font9 {
	        color: #ffffff;
	    }
	}
	
}

@media (min-width: 10px) and (max-width: 599px) {
	.content{
		.font3{
			font-size: 24px;
		}
		.font4{
			font-size: 14px;
			margin: 15px 35px;
		}
		.box1{
			width: auto;
			height: auto;
			display: block;
			.img1{
				width: 30%;
				height: auto;
				display: inline-block;
			}
			.img11{
				width: 100%;
				height: auto;
			}
			.m_xing{
				width: auto;
				height: auto;
				display: block;
				margin: 15px 3%;
				background-color: #EBFBFF;
				
			}

			.box2{
				width: 70%;
				height: auto;
				display: inline-block;
				
				background: none;
				
				.font5{
					margin-top: 0px;
				}
				.font9{
					width: 90%;
					overflow: hidden;
					text-overflow: ellipsis;
					display:-webkit-box;
					-webkit-box-orient:vertical; 
					-webkit-line-clamp:2; 
				}
			}
			.box2_2{
				position: relative;
				top: -15px;
			}

		}
		
	}
	.bottom{
		padding-top: 30px;
		.title2{
			.font12{
				font-size: 24px;
			}
			.font13{
				font-size: 14px;
			}
		}
		.box6{
			width: auto;
			height: auto;
			margin-top: 30px;
			display: block;
			.font15{
				width: auto;
			}
			.box7{
				width: auto;
				margin-right:0;
				margin: 15px 3%;
				padding: 0 15px;
				.box8{
					margin-right: 15px;
				}
			}
		}
	}
}

</style>